<template>
    <div class="container">
        <div class="product-top">
            <div class="title">ALL Products ({{total}})</div>
            <div class="filter-box">
                <el-select v-model="type" placeholder="" @change="change">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="list">
            <div v-for="(item,index) in list" :key="index" class="list-item">
                <div class="click-info" @click="toDetail(item.id)">
                    <img class="img" :src="item.pic_url" />
                    <div class="name">{{item.good_title}}</div>
                </div>
                <div class="price">{{$currencySymbol}}{{item.price_trans}}</div>
            </div>
        </div>
        <div class="pagination-box">
            <el-pagination
            class="pagination-pc" 
            :hide-on-single-page="true"
            :current-page="currentPage"
            :pager-count="11"
            :page-size="pageSize"
            :total="total" 
            @current-change="handleCurrentChange" 
            background 
            layout="prev, pager, next">
            </el-pagination>
            <el-pagination
            class="pagination-h5" 
            :hide-on-single-page="true"
            :current-page="currentPage"
            :pager-count="5"
            :page-size="pageSize"
            :total="total" 
            @current-change="handleCurrentChange" 
            background 
            layout="prev, pager, next">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                options: [
                    {
                        value: '0',
                        label: 'Sort By'
                    },
                    {
                        value: '1',
                        label: 'Sale'
                    },
                    {
                        value: '2',
                        label: 'Price↑'
                    },
                    {
                        value: '3',
                        label: 'Price↓'
                    },
                    {
                        value: '4',
                        label: 'New In'
                    }
                ],
                type: '0',
                category_id: '',
                keyword: '',
                list: [],
                pageSize: 0,
                total: 0,
                currentPage: 1
            }
        },
        created() {
            this.category_id = this.$route.query.category_id || '0'
            this.keyword = this.$route.query.keyword || ''
            this.getList()
        },
        computed: {
            curQuery() {
                return this.$route.query
            }
        },
        watch: {
            curQuery(val) {
                // console.log(val)
                this.category_id = val.category_id || '0'
                this.keyword = val.keyword || ''
                this.currentPage = 1
                this.getList()
            }
        },
        methods: {
            change(e) {
                // console.log(this.type)
                this.currentPage = 1
                this.getList()
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getList()
            },
            
            getList() {
                this.$api.goods_list({
                    goods_category_id: this.category_id,
                    sort: this.type,
                    keyword: this.keyword,
                    page: this.currentPage
                },true).then(res => {
                    this.list = res.data.data
                    this.pageSize = res.data.per_page
                    this.total = res.data.total
                })
            },
            
            toDetail(id) {
                this.$router.push({
                    path: '/detail',
                    query: {
                        id
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
            width: 100%;
            
            .product-top {
                width: 100%;
                box-sizing: border-box;
                padding: 2rem 0 1.5rem;
                border-bottom: 1px solid #e9e9e9;
                
                .title {
                    width: 100%;
                    margin-bottom: 1.4rem;
                    font-size: 2.4rem;
                    font-weight: bold;
                    color: #333333;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .filter-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
            }
            
            .list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                padding: 2rem 0 0;
                
                .list-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 24%;
                    margin-left: 1.33%;
                    
                    &:nth-child(4n+1) {
                        margin-left: 0;
                    }
                    
                    &:nth-child(n+5) {
                        margin-top: 1.6rem;
                    }
                    
                    .click-info {
                        width: 100%;
                        
                        .img {
                            display: block;
                            width: 100%;
                            cursor: pointer;
                        }
                        
                        .name {
                            width: 100%;
                            padding: 1.4rem 0 1rem;
                            font-size: 1.4rem;
                            color: #333333;
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                        }
                    }
                    
                    .price {
                        width: 100%;
                        font-size: 1.6rem;
                        font-weight: bold;
                        color: #000000;
                        text-align: center;
                        word-break: break-all;
                    }
                }
            }
            
            .pagination-box {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 4rem 0;
                
                .pagination-h5 {
                    display: none;
                }
            }
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
            width: 100%;
            
            .product-top {
                width: 100%;
                box-sizing: border-box;
                padding: $h5-box-padding;
                border-bottom: 1px solid #e9e9e9;
                
                .title {
                    width: 100%;
                    margin-bottom: 2.5rem;
                    font-size: 2rem;
                    font-weight: bold;
                    color: #333333;
                    text-align: center;
                    word-wrap: break-word;
                }
                
                .filter-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
            }
            
            .list {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                box-sizing: border-box;
                padding: $h5-box-padding $h5-box-padding 0;
                
                .list-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 48%;
                    margin-left: 4%;
                    
                    &:nth-child(2n+1) {
                        margin-left: 0;
                    }
                    
                    &:nth-child(n+3) {
                        margin-top: 1.5rem;
                    }
                    
                    .click-info {
                        width: 100%;
                        
                        .img {
                            display: block;
                            width: 100%;
                            cursor: pointer;
                        }
                        
                        .name {
                            width: 100%;
                            padding: 1.2rem 0 .8rem;
                            font-size: 1.4rem;
                            color: #333333;
                            text-align: center;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            cursor: pointer;
                        }
                    }
                    
                    .price {
                        width: 100%;
                        font-size: 1.5rem;
                        font-weight: bold;
                        color: #000000;
                        text-align: center;
                        word-break: break-all;
                    }
                }
            }
            
            .pagination-box {
                display: flex;
                justify-content: center;
                width: 100%;
                padding: 3rem 0;
                
                .pagination-pc {
                    display: none;
                }
            }
        }
    }
</style>